import { useInterpret, useSelector } from "@xstate/react";
import * as React from "react";
import { useContext, useMemo } from "react";
import { assign, createMachine, DoneInvokeEvent, EventObject, State } from "xstate";
import GetGroups from "../../../graphql/DDPro/GetGroups.graphql";
import { RegisterForDDProPage } from "../../../pages";
import { client } from "../../../utils/apollo-client";
import { useNavigate } from "../../../components/Link/Link";
import { DDLogo } from "../../../components/Logo/DDLogo";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { useDDProTranslations } from "../useDDProTranslations";
import { Code } from "./states/qr/Code";
import { joinGroupToUrl } from "./states/qr/utils";
import { GroupIdPayload, SubscriptionPayer } from "./types";

type MachineContext = {
  groupID?: string;
  groupTitle?: string;
  subscriptionPayer: SubscriptionPayer;
};

type MachineState = State<
  MachineContext,
  EventObject,
  any,
  {
    value: any;
    context: MachineContext;
  }
>;

const machine = createMachine<MachineState>({
  initial: "load",
  states: {
    load: {
      invoke: {
        src: "loadGroup",
        onDone: [
          {
            target: "qr",
            actions: ["save"],
            cond: "isDDProAdmin",
          },
          { actions: ["redirectToPro"] },
        ],
        onError: { target: "qr", actions: ["save"] },
      },
    },
    qr: {},
  },
});

const groupIDSelector = (state: MachineState) => state.context.groupID;
const groupTitleSelector = (state: MachineState) => state.context.groupTitle;
const subscriptionPayerSelector = (state: MachineState) => state.context.subscriptionPayer;

function DDProPDF() {
  const { locale } = useContext(pageContext);
  const tt = useDDProTranslations();
  const navigate = useNavigate();

  const pro = useInterpret(machine, {
    actions: {
      redirectToPro: () => {
        navigate({ to: RegisterForDDProPage });
      },
      save: assign((ctx, event) => {
        const [group] = (event as DoneInvokeEvent<GroupIdPayload>).data.getDDProGroups;

        return {
          ...ctx,
          groupID: group.id,
          groupTitle: group.title,
          subscriptionPayer: group.subscriptionPayer,
        };
      }),
    },
    services: {
      loadGroup: () => client.query({ query: GetGroups }).then((res) => res.data),
    },
    guards: {
      isDDProAdmin: (_, event) => (event as DoneInvokeEvent<GroupIdPayload>).data.getDDProGroups.length > 0,
    },
  });

  pro.start();

  const groupID = useSelector(pro, groupIDSelector);
  const groupTitle = useSelector(pro, groupTitleSelector);
  const subscriptionPayer = useSelector(pro, subscriptionPayerSelector);

  const url = useMemo(
    () => (groupID && groupTitle ? joinGroupToUrl(locale, document.location.origin, groupID, groupTitle) : ""),
    [groupID, groupTitle, locale]
  );

  return (
    <div className="m-auto ">
      <SEOReactHelmet title={`Diet Doctor - DD Pro`} lang={locale as string} />
      <div className="py-12" style={{ width: "900px", height: "1300px" }}>
        <div className="w-full flex flex-col items-center px-12">
          <DDLogo />
          <div className="border-t-2 border-blue h-1 mt-12 mb-24 w-full"></div>
          <h1 className="m-0 p-0 text-5xl font-medium">{tt.takeChargeOfYourHealth}</h1>
          <h2 className="mx-0 my-4 p-0 text-2xl font-normal">{tt.poweredByDietDoctor}</h2>
          {subscriptionPayer === SubscriptionPayer.MEMBER && (
            <div className="my-24 bg-lighter-grey rounded-full w-96 h-96 flex justify-center items-center">
              <Code width={270} url={url} />
            </div>
          )}
          {subscriptionPayer === SubscriptionPayer.ADMIN && (
            <div className="p-16 my-24">{tt.cannotCreateAQrCodePleaseContactYourDdProAdministrator}</div>
          )}
          <p className="text-center">
            {tt.pleaseScanTheQrCodeToSignUpForDietDoctorViaGroupTitleHere(groupTitle!)}.
          </p>
          <p className="text-center">
            {
              tt.dietDoctorIsOneOfTheMostTrustedResourcesForMetabolicHealthAndWeightLossWithHundredsOfMedicallyReviewedGuidesPersonalizedMealPlansAndOver1000LowCarbRecipes
            }
          </p>
        </div>
      </div>
    </div>
  );
}

// ts-prune-ignore-next
export default DDProPDF;

import * as React from "react";

export function DDLogo() {
  return (
    <svg width="50" height="66" viewBox="0 0 50 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9431 0H0.607483V8.25172H16.9431C30.4744 8.25172 41.4397 19.3297 41.4397 33C41.4397 46.6703 30.4676 57.7483 16.9431 57.7483H0.607483V66H16.9431C34.9803 66 49.6075 51.2225 49.6075 33C49.6075 14.7775 34.9803 0 16.9431 0Z"
        fill="#0E3478"
      />
      <path
        d="M33.2719 33C33.2719 23.8887 25.9617 16.4966 16.9363 16.4966H0.607483V49.4966H16.9431C25.9617 49.5034 33.2719 42.1113 33.2719 33ZM8.77528 24.7483H16.9431C21.4558 24.7483 25.1109 28.4409 25.1109 33C25.1109 37.5591 21.4558 41.2517 16.9431 41.2517H8.77528V24.7483Z"
        fill="#0E3478"
      />
    </svg>
  );
}
